import logo from './logo.svg';
import './App.css';
import moment from 'moment';
import { useState, useEffect } from 'react';

function App() {
  const [useNow, setNow] = useState(moment());
  const [useDifference, setDifference] = useState("");
  const [useYear, setYear] = useState(0);
  const [useMonth, setMonth] = useState(0);
  const [useDay, setDay] = useState(0);
  const [useHour, setHour] = useState(0);
  const [useMinute, setMinute] = useState(0);
  const [useSecond, setSecond] = useState(0);

  const target = moment('2022-05-28 16:15:00');

  setInterval(() => {
    setNow(moment());
  }, 1000);

  useEffect(() => {
    const diff = useNow.diff(target);
    const duration = moment.duration(diff);
    
    // x year y months z days a hours b minutes c seconds
    const years = Math.floor(duration.asYears());
    duration.subtract(years, 'years');
    const months = Math.floor(duration.asMonths());
    duration.subtract(months, 'months');
    const days = Math.floor(duration.asDays());
    duration.subtract(days, 'days');
    const hours = Math.floor(duration.asHours());
    duration.subtract(hours, 'hours');
    const minutes = Math.floor(duration.asMinutes());
    duration.subtract(minutes, 'minutes');
    const seconds = Math.floor(duration.asSeconds());
    duration.subtract(seconds, 'seconds');
    setYear(years);
    setMonth(months);
    setDay(days);
    setHour(hours);
    setMinute(minutes);
    setSecond(seconds);
    // setDifference(`${years} yıl ${months} ay ${days} gün ${hours} saat ${minutes} dakika ${seconds} saniye`);
  }, [useNow]);


  return (
    <div className="App">
      <header className="App-header">
      <code>2022-05-28 16:00</code>
        <p style={{
          display: "block",
          fontSize: "5rem",
          fontWeight: "bold",
          margin: "0 auto",
          textAlign: "center",
          color: "#61dafb",
        }}>
          <span style={{color: "#ff0000"}}>{useYear}</span> yıl <span style={{color: "#dd0000"}}>{useMonth}</span> ay <span style={{color: "#bb0000"}}>{useDay}</span> gün <span style={{color: "#bb0000"}}>{useHour}</span> saat <span style={{color: "#bb0000"}}>{useMinute}</span> dakika <span style={{color: "#bb0000"}}>{useSecond}</span> saniye
          {useDifference}
        </p>
      </header>
    </div>
  );
}

export default App;
